import Vue from 'vue'
import VueTheMask from 'vue-the-mask'
import money from 'v-money'

import VueRouter from 'vue-router'

import App from './App.vue'
import Home from './Home.vue'

// import './assets/scss/main.css'
Vue.config.productionTip = false

// register directive v-money and component <money>
Vue.use(VueTheMask)
Vue.use(money, {precision: 4})
Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    { path: '/', component: Home }
  ]
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
