<template>
  <router-view></router-view>
</template>

<script>
export default {
 mounted() {
    console.log(this.$route)
 }
}
</script>

<style>

</style>