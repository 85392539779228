<template>
  <div class="widget" v-show="show">
    <div class="widget-navbar">
      <p>Faça uma doação</p>
      <a v-show="closeModal" @click="show = false"
        ><i class="fal fa-times"></i
      ></a>
    </div>
    <div
      class="widget-header"
      :style="`background-image: url(${itemSelected.image})`"
    >
      <div class="widget-header-logo">
        <img src="/images/logo.jpg" alt="" />
      </div>
      <div class="widget-header-info">
        <h4 v-if="!itemSelected.other">R$ {{ itemSelected.value }}</h4>
        <p>{{ itemSelected.title }}</p>
        <div class="widget-header-input" v-if="itemSelected.other">
          <span>R$</span>
          <input
            type="text"
            v-money="money"
            @change="(e) => (itemSelected.value = e.target.value)"
          />
        </div>
      </div>
    </div>
    <div class="widget-values" v-show="step !== 3">
      <template v-for="(item, index) in values">
        <div :key="index">
          <button
            @click="itemSelected = item"
            :class="{ active: itemSelected.value == item.value }"
          >
            {{ !item.other ? `R$ ${item.value}` : "Outro" }}
          </button>
        </div>
      </template>
    </div>
    <div class="widget-content">
      <Payment
        v-show="step === 1"
        :payments="payments"
        :value="itemSelected.value"
        :payment="payment"
        @onSelectPayment="selectPayment($event)"
        @onContinue="goTo($event)"
      />
      <Form
        v-show="step === 2"
        :payment="payment"
        :fields="fields"
        @onContinue="goTo($event)"
        @onChangePayment="payment = $event"
      ></Form>
      <Success v-show="step === 3" :payment="payment"></Success>
      <div class="widget-footer">
        <span>©2021 • AUC</span>
        <span
          ><a href="#">Política de Privacidade</a> e
          <a href="#">Termos de Uso</a>.</span
        >
      </div>
    </div>
    <div class="widget-loading" v-show="loading"></div>
    <div class="widget-snackbar" v-show="error">
      <i class="fas fa-exclamation-triangle"></i>
      <p class="message">
        Erro de conexão. Por favor tente novamente mais tarde.
      </p>
      <a @click="error = false"><i class="fal fa-times"></i></a>
    </div>
  </div>
</template>

<script>
/* 
Modos de pagamento
Valores + opção de outro valor
Campos opcionais do cartão de crédito 
  CPF
	Estado
	Nome no cartão
ID da instituição
Se eh um modal

?payments=card,barcode,apple,google&values=10,50,100,500,-1&fields=name,email,cpf,state,cardname&id=1&type=modal
*/
import Payment from "./components/Payment.vue";
import Form from "./components/FormDoacaoSimplificada.vue";
import Success from "./components/Success.vue";
export default {
  components: { Payment, Form, Success },
  data() {
    return {
      step: 1,
      payments: ["card", "barcode", "apple", "google"],
      show: true,
      closeModal: false,
      loading: false,
      error: false,
      fields: [],
      values: [
        {
          value: 10,
          title: "Maça",
          image: "/images/maca.jpg",
        },
        {
          value: 50,
          title: "Cesta Básica",
          image: "/images/cesta.jpg",
        },
        {
          value: 100,
          title: "Kit Educação",
          image: "/images/educacao.jpg",
        },
        {
          value: 500,
          title: "Uniformes Escolares",
          image: "/images/uniformes.jpg",
        },
        {
          value: -1,
          title: "Informe o valor da doação",
          image: "/images/criancas.jpg",
          other: true,
        },
      ],
      itemSelected: [],
      payment: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    selectPayment(e) {
      this.payment = e;
    },
    goTo(e) {
      if (this.payment === "apple" || this.payment === "google") {
        this.loading = true;
        setTimeout(() => {
          this.error = true;
          this.loading = false;
        }, 2000);
        return false;
      }

      if (e === 2) {
        this.itemSelected.other = false;
      }

      this.step = e;
    },
  },
  mounted() {
    console.log(this.$route);
    if (this.$route.query.payments)
      this.payments = this.$route.query.payments.split(",");
    if (this.$route.query.fields)
      this.fields = this.$route.query.fields.split(",");
    if (this.$route.query.values) {
      let _values = this.$route.query.values.split(",");
      let data = this.values.filter((e) =>
        _values.includes(e.value.toString())
      );
      this.values = data;
    }
    this.itemSelected = this.values[0];
    this.closeModal = this.$route.query.type === "modal" ? true : false;
  },
};
</script>
<style>
</style>