<template>
  <div>
    <div class="widget-listitem">
      <span>Formas de pagamento</span>
    </div>
    <a v-if="payments.includes('card')" @click="$emit('onSelectPayment', 'card')" :class="{selected: payment == 'card'}" class="widget-listitem">
      <i class="widget-listitem-icon fal fa-credit-card"></i>
      <span><b>Cartão de crédito</b></span>
    </a>
    <a v-if="payments.includes('barcode')" @click="$emit('onSelectPayment', 'barcode')" :class="{selected: payment == 'barcode'}" class="widget-listitem">
      <i class="widget-listitem-icon fal fa-barcode-alt"></i>
      <span><b>Boleto bancário</b></span>
    </a>
    <a v-if="payments.includes('apple')" @click="$emit('onSelectPayment', 'apple')" :class="{selected: payment == 'apple'}" class="widget-listitem">
      <i class="widget-listitem-icon fab fa-apple"></i>
      <span><b>Apple Pay</b></span>
    </a>
    <a v-if="payments.includes('google')" @click="$emit('onSelectPayment', 'google')" :class="{selected: payment == 'google'}" class="widget-listitem">
      <i class="widget-listitem-icon fab fa-google"></i>
      <span><b>Google Pay</b></span>
    </a>
    <div class="widget-listitem">
      <button class="widget-btn" :disabled="parseInt(value) <= 0 || !payment" @click="$emit('onContinue', 2)">Continuar</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['payments', 'value', 'payment'],
  mounted() {
    console.log(this.value)
  }
};
</script>

<style>
</style>