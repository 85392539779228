<template>
  <div>
    <div class="widget-px widget-pt">
      <div class="widget-form-group" v-show="form.selectPayment === 'card'">
        <select
          name="recorrencia"
          id=""
          class="widget-form-control widget-form-select"
          v-model="form.recorrencia"
        >
          <option disabled selected>Recorrência</option>
          <option value="1">Recorrente</option>
          <option value="2">Mensal</option>
        </select>
      </div>
      <div class="widget-form-group">
        <select
          name="payment"
          id=""
          class="widget-form-control widget-form-select"
          v-model="form.selectPayment"
          @change="$emit('onChangePayment', form.selectPayment)"
        >
          <option value="card">Cartão de crédito</option>
          <option value="barcode">Boleto bancário</option>
        </select>
        <span class="hint"></span>
      </div>
      <hr class="widget-divider" />
      <div
        class="widget-form-group"
        ref="nameInput"
        v-if="fields.includes('name')"
      >
        <div class="widget-form-control">
          <input
            type="text"
            placeholder="Nome completo"
            v-model="form.name"
            @blur="validate('name')"
          />
          <i class="widget-listitem-icon fal fa-user"></i>
        </div>
        <span class="hint">Por favor, digite o seu nome</span>
      </div>
      <div
        class="widget-form-group"
        ref="emailInput"
        v-if="fields.includes('email')"
      >
        <div class="widget-form-control">
          <input
            type="text"
            placeholder="E-mail"
            v-model="form.email"
            @blur="validate('email')"
          />
          <i class="widget-listitem-icon fal fa-envelope"></i>
        </div>
        <span class="hint">Por favor, digite o seu email</span>
      </div>
    </div>

    <div id="bar-code" v-show="form.selectPayment === 'barcode'">
      <div class="widget-px">
        <div class="widget-boxinfo">
          <p>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit. Exercitation
            veniam consequat sunt nostrud amet.
          </p>
        </div>
      </div>
      <div class="widget-listitem">
        <button class="widget-btn" @click="$emit('onContinue', 3)">
          Gerar boleto
        </button>
      </div>
    </div>

    <div id="credit-card" v-show="form.selectPayment === 'card'">
      <div class="widget-px">
        <div class="widget-form-group" ref="cardNumberInput">
          <div class="widget-flex">
            <div class="widget-form-control">
              <input
                type="text"
                placeholder="Número do cartão"
                v-mask="'#### #### #### ####'"
                v-model="form.cardNumber"
                @blur="
                  validate('cardNumber');
                  getCardFlag();
                "
              />
              <i class="widget-listitem-icon fal fa-credit-card"></i>
            </div>
            <div class="flag-box" :class="{ active: flag != '' }">
              <img :src="`/images/flags/${flag}.svg`" alt="" />
            </div>
          </div>
          <span class="hint">Por favor, digite o número do cartão</span>
        </div>
        <div
          class="widget-form-group"
          ref="cardNameInput"
          v-if="fields.includes('cardname')"
        >
          <div class="widget-form-control">
            <input
              type="text"
              placeholder="Nome no cartão"
              v-model="form.cardName"
              @blur="validate('cardName')"
            />
            <i class="widget-listitem-icon fal fa-user"></i>
          </div>
          <span class="hint">Por favor, digite o nome no cartão</span>
        </div>
        <div
          class="widget-form-group"
          ref="cpfInput"
          v-if="fields.includes('cpf')"
        >
          <div class="widget-form-control">
            <input
              type="text"
              placeholder="CPF"
              v-mask="'###.###.###-##'"
              v-model="form.cpf"
              @blur="validate('cpf')"
            />
            <i class="widget-listitem-icon fal fa-id-card"></i>
          </div>
          <span class="hint">Por favor, digite o CPF</span>
        </div>
        <div
          class="widget-form-group"
          ref="stateInput"
          v-if="fields.includes('state')"
        >
          <div class="widget-form-control">
            <select
              name="payment"
              id=""
              class="widget-form-select"
              v-model="form.state"
              @blur="validate('state')"
            >
              <option value="">Estado</option>
              <option v-for="state in states" :key="state" :value="state">
                {{ state }}
              </option>
            </select>
            <i class="widget-listitem-icon fal fa-map-marker-alt"></i>
          </div>
          <span class="hint">Por favor, selecione o estado</span>
        </div>
        <div class="widget-form-group" ref="validateInput">
          <div class="widget-form-control">
            <input
              type="text"
              placeholder="Data de validade"
              v-mask="'##/##'"
              v-model="form.validate"
              @blur="validate('validate')"
            />
            <i class="widget-listitem-icon fal fa-calendar"></i>
          </div>
          <span class="hint">Por favor, digite a Data de validade</span>
        </div>
        <div class="widget-form-group widget-pb-0" ref="cvcInput">
          <div class="widget-form-control">
            <input
              type="text"
              placeholder="CVC"
              v-mask="'###'"
              v-model="form.cvc"
              @blur="validate('cvc')"
            />
            <i class="widget-listitem-icon fal fa-credit-card-front"></i>
          </div>
          <span class="hint">Por favor, digite o CVC</span>
        </div>
      </div>
      <div class="widget-listitem">
        <button
          class="widget-btn widget-btn-green"
          :disabled="!allowSubscribe"
          @click="$emit('onContinue', 3)"
        >
          Concluir doação
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["payment", "fields"],
  data() {
    return {
      flag: "",
      form: {
        recorrencia: "1",
        selectPayment: "card",
        cardNumber: "",
        cardName: "",
        cpf: "",
        state: "",
        validate: "",
        cvc: "",
        name: "",
        email: ""
      },
      states: [
        "Acre ",
        "Alagoas ",
        "Amapá ",
        "Amazonas ",
        "Bahia ",
        "Ceará ",
        "Distrito Federal ",
        "Espírito Santo ",
        "Goiás ",
        "Maranhão ",
        "Mato Grosso ",
        "Mato Grosso do Sul ",
        "Minas Gerais ",
        "Pará ",
        "Paraíba ",
        "Paraná ",
        "Pernambuco ",
        "Piauí ",
        "Rio de Janeiro ",
        "Rio Grande do Norte ",
        "Rio Grande do Sul ",
        "Rondônia ",
        "Roraima ",
        "Santa Catarina ",
        "São Paulo ",
        "Sergipe ",
        "Tocantins ",
      ],
    };
  },
  watch: {
    payment() {
      this.form.selectPayment = this.payment;
    },
  },
  computed: {
    allowSubscribe() {
      return (
        this.form &&
        this.form.cardNumber &&
        (this.fields.includes("name") ? this.form.cardName : true) &&
        (this.fields.includes("email") ? this.form.cpf : true) &&
        (this.fields.includes("cardname") ? this.form.cardName : true) &&
        (this.fields.includes("cpf") ? this.form.cpf : true) &&
        (this.fields.includes("state") ? this.form.state : true) &&
        this.form.validate &&
        this.form.cvc
      );
    },
  },
  methods: {
    validate(property) {
      let isValid = this.form[property].length > 0;
      if (isValid) this.$refs[property + "Input"].classList.remove("has-error");
      else this.$refs[property + "Input"].classList.add("has-error");
      return isValid;
    },
    getCardFlag() {
      var cardnumber = this.form.cardNumber.replace(/[^0-9]+/g, "");

      var cards = {
        amex: /^3[47][0-9]{13}/,
        aura: /^(5078\d{2})(\d{2})(\d{11})$/,
        //banes
        diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
        //hiper
        hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}/,
        mastercard: /^5[1-5][0-9]{14}/,
        visa: /^4[0-9]{12}(?:[0-9]{3})/,
      };

      for (var flag in cards) {
        if (cards[flag].test(cardnumber)) {
          this.flag = flag;
        } else {
          this.flag = "";
        }
      }

      return false;
    },
  },
};
</script>

<style>
</style>